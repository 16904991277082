<template>
  <div class="grid">
    <v-card dark hover ripple :color="getRandomColor()" elevation="0.5">
      <v-skeleton-loader type="card" :loading="loading">
        <v-card-title>
          {{ name }}
          <v-spacer></v-spacer>
          <v-icon>fas fa-{{ icon }}</v-icon>
        </v-card-title>
        <v-card-subtitle class="headline heading">
          <v-icon v-if="money">fas fa-dollar-sign</v-icon>
          <count-to
            :startVal="startVal"
            :endVal="count"
            :duration="duration"
          ></count-to
        ></v-card-subtitle>
      </v-skeleton-loader>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "stat_card",
  data() {
    return {
      startVal: 0,
      duration: 3000
    };
  },
  components: {
    countTo: () => import("vue-count-to")
  },
  props: {
    icon: {
      type: String,
      default: "user"
    },
    name: {
      type: String,
      default: ""
    },
    count: {
      type: Number,
      default: 1
    },
    money: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>
